import { AUTH_MODAL_STATE } from "const";
import { useAuthRedirectPopup } from "hooks";
import { observer } from "mobx-react-lite";

function LoginPage() {
  useAuthRedirectPopup(AUTH_MODAL_STATE.LOGIN);
  return null;
}

export default observer(LoginPage);
